import style from './profilesidebar.module.scss';
import { useNavigate } from 'react-router-dom';
const ProfileSidebar = ({ name, locationData, currentLocation }) => {
  const navigate = useNavigate();
  return (
    <div className={style.sideBar}>
      <div className={style.title}>
        <h5>{name}</h5>
      </div>
      <ul>
        {locationData
          .filter(i => i.path)
          .map(i => {
            return (
              <li className={currentLocation === i.path ? style.active : null}>
                <a href={`/profile/${i.path}`}>{i.title}</a>
              </li>
            );
          })}
        <li>
          <button
            onClick={() => {
              localStorage.removeItem('token');
              navigate('/');
            }}
          >
            Çıkış Yap
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ProfileSidebar;
