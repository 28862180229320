import style from './payment.module.scss';
import MasterPassLogo from '../../../assets/img/brand/masterpass-logo.png';
import { CloseIcon } from '../../../assets/icon';
import { useState, useEffect } from 'react';
import Cards from 'react-credit-cards';
import axios from 'axios';
import { ApiRoutes } from '../../../utils/ApiRoutes';
import GarantiPaymentForm from '../../Payment/GarantiPaymentForm';

const MasterPass = () => {
  return (
    <div className={style.masterpass}>
      <div className={style.info}>
        <h5>
          Masterpass'e kayıtlı kartlarınız bulunmaktadır. Kartlarınızı kullanmak
          ister misin?
        </h5>
        <button>Evet isterim</button>
      </div>
      <img src={MasterPassLogo} alt="master" />
      <button className={style.close}>
        <CloseIcon />
      </button>
    </div>
  );
};

const Payment = ({ address, cart, billAdress }) => {
  const [cardNumber, setCardNumber] = useState({
    name: '',
    cardNumber: '',
    month: '',
    year: '',
    cvc: '',
    isFocused: false,
  });

  const [paymentData, setPaymentData] = useState();
  const [ip, setIP] = useState('');

  async function getIP() {
    const response = await fetch('http://api.ipify.org/?format=json');
    const data = await response.json();
    return data;
  }

  useEffect(() => {
    axios
      .post(ApiRoutes.payment.get, {
        basketId: cart[0].id,
        addressId: address,
        addressInvoiceId: billAdress ? billAdress : address,
      })
      .then(res => {
        console.log('paymentRes', res);
        setPaymentData(res.data);
      })
      .catch(err => {
        console.log(err);
      });

    getIP().then(data => setIP(data.ip));
  }, []);

  var date = new Date();
  var now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  console.log(new Date(now_utc));
  console.log(date.toISOString());

  return (
    <div className={style.payment}>
      <div className={style.buttonWrapper}>
        <button className={style.active}>KREDİ / BANKA KARTI</button>
        {/* <button>MASTERPASS</button>
        <button>GIFT CARD</button> */}
      </div>
      {paymentData ? (
        <div className={style.content}>
          <GarantiPaymentForm
            value={{
              card: cardNumber,
              payment: paymentData,
              ip: ip,
              date: date.toISOString(),
            }}
          />
          <form>
            <label>
              <h5>KART NUMARASI</h5>
              <input
                onChange={e =>
                  setCardNumber({ ...cardNumber, cardNumber: e.target.value })
                }
                type="text"
                placeholder="KART NUMARASI"
              />
            </label>
            <label>
              <h5>KART ÜZERİNDEKİ AD SOYAD</h5>
              <input
                onChange={e =>
                  setCardNumber({ ...cardNumber, name: e.target.value })
                }
                type="text"
                placeholder="KART ÜZERİNDEKİ AD SOYAD"
              />
            </label>
            <div className={style.inputWrapper}>
              <label>
                <h5>AY</h5>
                <input
                  onChange={e =>
                    setCardNumber({ ...cardNumber, month: e.target.value })
                  }
                  type="text"
                  placeholder="AY"
                  maxLength={2}
                />
              </label>
              <label>
                <h5>YIL</h5>
                <input
                  onChange={e =>
                    setCardNumber({ ...cardNumber, year: e.target.value })
                  }
                  type="text"
                  placeholder="YIL"
                  maxLength={2}
                />
              </label>
              <label>
                <h5>CVC</h5>
                <input
                  onChange={e =>
                    setCardNumber({ ...cardNumber, cvc: e.target.value })
                  }
                  type="text"
                  placeholder="CVC"
                  maxLength={3}
                  onFocus={() =>
                    setCardNumber({ ...cardNumber, isFocused: 'cvc' })
                  }
                  onBlur={() => setCardNumber({ ...cardNumber, isFocused: '' })}
                />
              </label>
            </div>
          </form>
          <div className={style.creditCard}>
            <Cards
              cvc={cardNumber.cvc}
              expiry={`${cardNumber.month}${cardNumber.year}`}
              focused={cardNumber.isFocused}
              name={cardNumber.name}
              number={cardNumber.cardNumber}
              placeholders={{
                name: 'KART İSMİ',
              }}
            />
          </div>
        </div>
      ) : null}
      {/* <MasterPass /> */}
    </div>
  );
};

export default Payment;
