import { PageWrapper, ProductComp } from '../../components';
import style from './parfum.module.scss';
import useWindowDimensions from '../../helpers/windowWidth';
import HeadImage from '../../assets/img/collection/head-image-parfum.png';
import ProductImage from '../../assets/img/new-home/product-image.png';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { LangData } from '../../utils/LangData';

const Parfum = () => {
  const { width } = useWindowDimensions();
  const [data, setData] = useState();
  const currentLang = useSelector(state => state.settings.setting.lang);

  useEffect(() => {
    axios
      .get(`${ApiRoutes.product.getAll}`)
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {data ? (
        <PageWrapper>
          <div className={style.head}>
            <img src={data[0].basePhoto} />
            <div className={style.title}>
              <h2>
                {LangData[currentLang].parfum.title[0]}
                <br />
                {LangData[currentLang].parfum.title[1]}
              </h2>

              <p
                dangerouslySetInnerHTML={{
                  __html: data[0].baseText[currentLang],
                }}
              />
            </div>
          </div>
          <div className={style.productList}>
            {data.map(item => {
              return (
                <ProductComp
                  image={item.productPhoto}
                  price={item.price}
                  title={item.title[currentLang]}
                  link={`/product/${item.slug}`}
                />
              );
            })}
          </div>
        </PageWrapper>
      ) : null}
    </>
  );
};

export default Parfum;
