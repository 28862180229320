import style from './productdetail.module.scss';
import {
  PageWrapper,
  ProductSlider,
  ProductInfo,
  Inspiration,
  OlfactoryNotes,
  ProductImageGrid,
  CollectionGrid,
  Signature,
  ProductVideo,
  ProductService,
  BottomInfo,
  Newsletter,
} from '../../components';
import useWindowDimensions from '../../helpers/windowWidth';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const ProductDetail = () => {
  const [data, setData] = useState();
  const { width } = useWindowDimensions();
  const [basketData, setBasketData] = useState();
  const currentLang = useSelector(state => state.settings.setting.lang);
  const location = useLocation();
  const path = location.pathname.split('/')[2];

  useEffect(() => {
    axios
      .get(`${ApiRoutes.product.get}?slug=${path}`)
      .then(res => {
        console.log(res.data);
        setData(res.data[0]);

        axios
          .get(ApiRoutes.basket.get)
          .then(res => {
            console.log(res.data);

            setBasketData(res.data);
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <>
      {data ? (
        <PageWrapper
          text={`ANASAYFA / ${data.name.toUpperCase()}`}
          link={'/'}
          topBar
        >
          <div className={style.wrapper}>
            <div className={style.top}>
              <ProductSlider data={data.detailPhoto} />
              <ProductInfo
                basketData={basketData}
                name={data.name}
                price={data.price}
                priceLine={data.priceLine}
                id={data.id}
                lang={currentLang}
                info={data.paragraph[currentLang]}
                subTitle={data.subTitle[currentLang]}
                subTitleCap={data.subTitleCap[currentLang]}
              />
            </div>
            <div className={style.row}>
              <Inspiration
                data={data.inspiration[currentLang]}
                lang={currentLang}
              />
              {width < 944 && (
                <ProductImageGrid data={data.bottomPhoto} width={width} />
              )}
              <OlfactoryNotes data={data.fragreanceNotes} lang={currentLang} />
            </div>
            {width > 944 && (
              <ProductImageGrid data={data.bottomPhoto} width={width} />
            )}
            {/* <CollectionGrid title={'Diamond Collection'} /> */}
            {/* <div className={style.signatureWrapper}>
              <Signature lang={currentLang} />
            </div> */}
            {/* <CollectionGrid title={'You may also like'} /> */}
            <ProductVideo
              cover={data.bottomVideo}
              data={data.sliderVideo}
              width={width}
            />
            <ProductService lang={currentLang} />
          </div>
        </PageWrapper>
      ) : null}
    </>
  );
};

export default ProductDetail;
