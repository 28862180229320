import { GiftIcon, ArrowRightService } from '../../assets/icon';
import style from './productservice.module.scss';
import { LangData } from '../../utils/LangData';

const ProductService = ({ lang }) => {
  return (
    <div className={style.productService}>
      <div className={style.left}>
        <h2>
          {LangData[lang].product.service.title.map((item, index) => {
            return (
              <>
                {index !== 0 ? (
                  <>
                    <br />
                  </>
                ) : null}
                {item}
              </>
            );
          })}
        </h2>
        <GiftIcon />
      </div>
      <div className={style.right}>
        <ul>
          {LangData[lang].product.service.data.map((item, index) => {
            return (
              <li>
                <h5>{item.title}</h5>
                <p>{item.text}</p>
                <ArrowRightService />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ProductService;
