import {
  PageWrapper,
  FlashPhoto,
  Magazine,
  TripleCard,
  ProductCard,
  VideoCard,
  TextPart,
  HomeFooter,
  Crystal,
  Header,
  MobileMenu,
  Footer,
  Newsletter,
  HomeHead,
  ProductComp,
  BottomInfo,
} from '../../components';
import style from './home.module.scss';
import { useSelector } from 'react-redux';
import useWindowDimensions from '../../helpers/windowWidth';
import { useEffect, useState } from 'react';
import Triple1 from '../../assets/img/new-home/triple/triple-1.png';
import Triple2 from '../../assets/img/new-home/triple/triple-2.png';
import Triple3 from '../../assets/img/new-home/triple/triple-3.png';
import { Scene, Controller } from 'react-scrollmagic';
import { Tween } from 'react-gsap';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import ProductImage from '../../assets/img/new-home/product-image.png';
import Feed1 from '../../assets/img/new-home/feed/ceyhan.png';
import Feed2 from '../../assets/img/new-home/feed/shades.png';
import SignatureIcon from '../../assets/img/brand/signature-image.png';
import { BigFDIcon } from '../../assets/icon';
import { LangData } from '../../utils/LangData';

const Home = () => {
  const { width } = useWindowDimensions();
  const cartState = useSelector(state => state.cart.cart);
  const [basketData, setBasketData] = useState();
  const currentLang = useSelector(state => state.settings.setting.lang);
  const [data, setData] = useState();
  const [homeData, setHomeData] = useState();

  useEffect(() => {
    axios
      .get(`${ApiRoutes.product.getAll}`)
      .then(res => {
        setData(res.data);
      })
      .catch(err => {
        console.log(err);
      });

    axios
      .get(`${ApiRoutes.basket.get}`)
      .then(async res => {
        console.log('basketData', res.data);
        await setBasketData(res.data);
      })
      .catch(err => {
        console.log(err);
      });

    localStorage.removeItem('redirectProduct');
  }, []);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${ApiRoutes.home.get}?lang=${currentLang}`)
      .then(res => {
        console.log(res.data);
        setHomeData(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <div className={style.home}>
      {data && homeData ? (
        <>
          {width < 940 ? (
            <MobileMenu
              lang={currentLang}
              home
              cart={basketData ? basketData : 0}
            />
          ) : (
            <Header
              lang={currentLang}
              home
              cart={basketData ? basketData : 0}
              productLink={`/parfum`}
            />
          )}

          <HomeHead
            text={homeData.homeSlider[0][`text${currentLang.toUpperCase()}`]}
            discoverLink={homeData.homeSlider[0].discoverLink}
            lang={currentLang}
            width={width}
            video={homeData.homeSlider[0].link}
            videoMobile={homeData.homeSlider[0].linkMobile}
          />
          <div className={style.singleProduct}>
            {data.slice(1, 3).map(item => {
              return (
                <ProductComp
                  image={item.productPhoto}
                  price={item.price}
                  title={item.title[currentLang]}
                  link={`/product/${item.slug}`}
                  home
                />
              );
            })}
          </div>
          {width > 940 ? (
            <div className={style.triple}>
              <img src={homeData.photo1} />
              <img src={homeData.photo2} />
              <img src={homeData.photo3} />
            </div>
          ) : (
            <Controller>
              <div id="triple222" className={style.tripleWrapper}>
                <img src={homeData.photo1} />
                <Scene
                  duration={1000}
                  triggerElement={'#triple222'}
                  pin={'#triple222'}
                  triggerHook={0}
                >
                  {progress => (
                    <Tween
                      from={{
                        x: 100,
                      }}
                      to={{
                        x: -360,
                      }}
                      totalProgress={progress}
                      paused
                    >
                      <div className={style.mobileImageWrapper}>
                        <img src={homeData.photo2} />
                        <img src={homeData.photo3} />
                      </div>
                    </Tween>
                  )}
                </Scene>
              </div>
            </Controller>
          )}
          <div className={style.productWrapper}>
            <div className={style.title}>
              <h2>
                {LangData[currentLang].home.product.title.map((item, index) => {
                  return (
                    <>
                      {index !== 0 ? <br /> : null}
                      {item}
                    </>
                  );
                })}
              </h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: homeData.choseText[currentLang],
                }}
              />
            </div>
            <div className={style.productList}>
              {data.slice(0, 1).map(item => {
                return (
                  <ProductComp
                    image={item.productPhoto}
                    price={item.price}
                    title={item.title[currentLang]}
                    link={`/product/${item.slug}`}
                    home
                  />
                );
              })}
            </div>
          </div>
          <VideoCard
            video={homeData.bottomVideo}
            videoMobile={homeData.bottomVideoMobile}
            width={width}
          />
          <div className={style.greyArea}>
            <div className={style.quote}>
              <p
                dangerouslySetInnerHTML={{
                  __html: homeData.signatureText[currentLang],
                }}
              />
              <img src={SignatureIcon} />
              <BigFDIcon />
            </div>
            <div className={style.feedWrapper}>
              {homeData.homeDiscovers.map((item, index) => {
                return (
                  <div className={style.feed}>
                    <img src={item.photo} />
                    <h4>{item[`title${currentLang.toUpperCase()}`]}</h4>
                    <a href={item.link}>
                      {LangData[currentLang].home.head.discover}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
          <BottomInfo lang={currentLang} />
          <Newsletter lang={currentLang} />
          <div className={style.footerWrapper}>
            <Footer lang={currentLang} width={width} />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Home;
