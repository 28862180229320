import { Header, MobileMenu } from '../Header/Header';
import TopBar from '../TopBar/TopBar';
import style from './pagewrapper.module.scss';
import Footer from '../Footer/Footer';
import useWindowDimensions from '../../helpers/windowWidth';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import Newsletter from '../Newsletter/Newsletter';
import BottomInfo from '../BottomInfo/BottomInfo';

const PageWrapper = ({ children, topBar, text, link, logOut }) => {
  const { width } = useWindowDimensions();
  const [basketData, setBasketData] = useState();
  const currentLang = localStorage.getItem('lang');

  useEffect(() => {
    axios
      .get(ApiRoutes.basket.get)
      .then(res => {
        console.log(res.data);

        setBasketData(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <div className={style.pageWrapper}>
      <>
        {width < 940 ? (
          <MobileMenu lang={currentLang} cart={basketData} />
        ) : (
          <Header pageWrapper lang={currentLang} cart={basketData} />
        )}
      </>

      {topBar && (
        <TopBar text={text} type={topBar} logOut={logOut} link={link} />
      )}
      {children}
      <BottomInfo lang={currentLang} />
      <Newsletter lang={currentLang} />
      <Footer lang={currentLang} width={width} />
    </div>
  );
};

export default PageWrapper;
