import style from './summary.module.scss';
import ProductImage from '../../../assets/img/dummy/product.png';
import ProductImage2 from '../../../assets/img/home/render_png.png';

const Summary = ({ data, withProduct, click, cart, section }) => {
  const priceData = data.map(item =>
    parseFloat(Number(item.price) * item.quantity)
  );

  console.log('pricedata', data);
  const totalPrice = priceData.reduce((a, b) => a + b);
  // console.log(priceData);

  return (
    <div className={style.summary}>
      <div className={style.title}>
        <h2>Sipariş Özeti</h2>
        {/*  <button>SEPETİ PAYLAŞ</button>*/}
      </div>
      {withProduct ? (
        <div>
          {data.map(item => {
            return (
              <div className={style.product}>
                <img src={item.productPhoto} alt="product" />
                <div className={style.productInfo}>
                  <h3>{item.name}</h3>
                  <h4>{totalPrice} TL</h4>
                  <h5>{item.quantity} ADET</h5>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
      <ul>
        <li>
          <span>TOPLAM TUTAR</span>
          <h5>{totalPrice} TL</h5>
        </li>
        <li>
          <span>KARGO ÜCRETİ</span>
          <h5>0 TL</h5>
        </li>
        <li>
          <span>ETİKET İNDİRİMİ</span>
          <h5>0 TL</h5>
        </li>
        <li>
          <span>ÖDENECEK TUTAR</span>
          <h5>{totalPrice} TL</h5>
        </li>
      </ul>
      <div className={style.buttonWrapper}>
        {section === 'payment' ? (
          <button form="garantiForm" type="submit">
            SATIN AL
          </button>
        ) : (
          <button onClick={click}>SATIN AL</button>
        )}
        <button>İNDİRİM KUPONU EKLEYİN</button>
      </div>
    </div>
  );
};

export default Summary;
