import style from './header.module.scss';
import Logo from '../../assets/img/brand/logo.svg';
import LogoWhite from '../../assets/img/brand/logo-white.svg';
import LogoNeon from '../../assets/img/brand/logo-neon.svg';
import {
  ArrowDownIcon,
  CartIcon,
  NavigationIcon,
  SearchIcon,
  UserIcon,
  HamburgerIcon,
  MobileBasketIcon,
  MobileCloseIcon,
} from '../../assets/icon';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LangData } from '../../utils/LangData';
import { langChange } from '../../redux/actions/settingAction';
import { useState, useEffect } from 'react';

const MobileMenu = ({ home, cart, lang }) => {
  const cartState = useSelector(state => state.cart);
  const navigate = useNavigate();
  const currentUser = localStorage.getItem('token');
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const currentLang = useSelector(state => state.settings.setting.lang);
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const menuData = [
    {
      title: 'Shades Of Grey',
      type: 'link',
      link: '/shades-of-grey',
    },
    {
      title: 'Ceyhan Elmas',
      type: 'link',
      link: '/ceyhan-elmas',
    },
    {
      title: 'Koleksiyon',
      type: 'link',
      link: '/koleksiyon',
    },
    {
      title: 'Parfüm',
      type: 'link',
      link: '/parfum',
    },
    {
      title: 'Mağazalar',
      type: 'link',
      link: '/store',
    },
    {
      title: 'Bize Ulaşın',
      type: 'link',
      link: '/contact',
    },
    {
      title: 'Kariyer',
      type: 'link',
      link: '',
    },
    {
      title: 'Kişisel Verilerin Korunması Kanunu',
      type: 'link',
      link: '/kvkk',
    },
    {
      title: 'Kalite Çevre ve GMP Politikamız',
      type: 'link',
      link: '',
    },
    {
      title: 'İptal ve İade Koşulları',
      type: 'link',
      link: '/iptal-iade-kosullari',
    },
    {
      title: 'Üye Ol',
      type: 'link',
      link: '/login',
    },
    {
      title: 'Üye Girişi',
      type: 'link',
      link: '/register',
    },
    {
      title: 'Sepetim',
      type: 'link',
      link: '/cart?section=product',
    },
  ];

  return (
    <header
      style={{
        marginBottom: home ? '20px' : '',
      }}
      className={`${style.mobileMenu} ${home ? style.homeMobile : null} ${
        scrollPosition > 50 && home ? style.stickyMobile : null
      } ${isOpen ? style.full : null}`}
    >
      {isOpen ? (
        <ul className={style.menu}>
          {menuData.map(item => {
            return (
              <li>
                <a href={item.link}>{item.title}</a>
              </li>
            );
          })}
          <button className={style.lang}>
            <select
              value={currentLang}
              onChange={e => {
                dispatch(langChange(e.target.value));
              }}
            >
              <option value={'tr'}>TR</option>
              <option value={'en'}>EN</option>
            </select>
            <span>{currentLang.toUpperCase()}</span>
            <ArrowDownIcon />
          </button>
          <button className={style.signOut}>Çıkış Yap</button>
        </ul>
      ) : null}

      <button onClick={() => setOpen(!isOpen)} className={style.menuIcon}>
        <HamburgerIcon />
      </button>

      <>
        <a
          style={{
            marginLeft: home ? '0' : '45px',
          }}
          className={style.logo}
          href="/"
        >
          <img src={home ? LogoWhite : Logo} alt="logo" />
        </a>

        {isOpen ? (
          <button className={style.close} onClick={() => setOpen(false)}>
            <MobileCloseIcon />
          </button>
        ) : null}

        {scrollPosition > 50 && home ? (
          <>
            {!isOpen ? (
              <a className={style.mobileBasket} href="/cart?section=product">
                <MobileBasketIcon />
              </a>
            ) : null}
          </>
        ) : (
          <>
            {!isOpen ? (
              <div className={style.buttonWrapper}>
                <button
                  onClick={() => {
                    // localStorage.removeItem('token');
                    navigate('/profile');
                  }}
                >
                  <UserIcon />
                </button>
                <a href="/cart?section=product" className={style.cart}>
                  {/* <div className={style.circle} /> */}
                  <span>{currentUser ? cartState.quantity : 0}</span>
                  <CartIcon />
                </a>
              </div>
            ) : null}
          </>
        )}
      </>
    </header>
  );
};

const Header = ({ cart, home, lang, pageWrapper, productLink }) => {
  const navigate = useNavigate();
  const cartState = useSelector(state => state.cart);
  const currentLang = useSelector(state => state.settings.setting.lang);
  const currentUser = localStorage.getItem('token');
  const dispatch = useDispatch();
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header
      className={`${style.header} ${home && style.home} ${
        scrollPosition > 50 && !pageWrapper ? style.sticky : null
      }`}
    >
      {scrollPosition > 50 && !pageWrapper ? (
        <div className={style.stickyHeader}>
          <a className={style.logo} href="/">
            <img src={LogoNeon} alt="logo" />
          </a>
          <a
            className={style.buy}
            href={productLink ? productLink : '/product/shades-of-grey'}
          >
            {LangData[lang].shadesOfGrey.buy.title}
          </a>
        </div>
      ) : (
        <div className={style.wrapper}>
          <div className={style.left}>
            <ul className={style.linkWrapper}>
              {LangData[lang].component.header.slice(0, 3).map(item => {
                return (
                  <li>
                    <a href={item.path}>{item.title}</a>
                  </li>
                );
              })}
            </ul>
            {/* <li>
              <a href="/product">PARFUM</a>
            </li>
            <li>
              <a href="/product">KOLEKSIYON</a>
            </li>
            <li>
              <a href="/product">ABOUT US</a>
            </li> */}
          </div>

          <a className={style.logo} href="/">
            <img src={home ? LogoWhite : Logo} alt="logo" />
          </a>

          <div className={style.right}>
            <ul className={style.linkWrapper}>
              {LangData[lang].component.header.slice(3, 10).map(item => {
                return (
                  <li>
                    <a href={item.path}>{item.title}</a>
                  </li>
                );
              })}
            </ul>
            {/* <a className={style.finder} href="/product">
            <span>FRAGRANCE FINDER</span>
          </a> */}
            <ul className={style.buttonWrapper}>
              <button className={style.lang}>
                <select
                  value={currentLang}
                  onChange={e => {
                    dispatch(langChange(e.target.value));
                  }}
                >
                  <option value={'tr'}>TR</option>
                  <option value={'en'}>EN</option>
                </select>
                <span>{currentLang.toUpperCase()}</span>
                <ArrowDownIcon />
              </button>
              {/* <button>
              <SearchIcon />
            </button>
             */}
              <button
                onClick={() => {
                  // localStorage.removeItem('token');
                  navigate('/store');
                }}
                className={style.navigation}
              >
                <NavigationIcon />
              </button>
              <button
                onClick={() => {
                  // localStorage.removeItem('token');
                  navigate('/profile');
                }}
              >
                <UserIcon />
              </button>
              <a href="/cart?section=product" className={style.cart}>
                {/* <div className={style.circle} /> */}
                <span>{currentUser ? cartState.quantity : 0}</span>
                <CartIcon />
              </a>
            </ul>
          </div>
        </div>
      )}
      {home && scrollPosition < 50 ? <div className={style.topCover} /> : null}
    </header>
  );
};

export { MobileMenu, Header };
