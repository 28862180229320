import style from './productcomp.module.scss';

const ProductComp = ({ image, title, price, home, link }) => {
  return (
    <a href={link} className={style.product}>
      <img className={home ? style.home : null} src={image} />
      <h4>{title}</h4>
      <p>EXTRAIT DE PARFUM</p>
      <h5>₺{price}</h5>
    </a>
  );
};

export default ProductComp;
