import style from './footer.module.scss';
import { LogoStroke, FooterDownArrow } from '../../assets/icon';
import Logo from '../../assets/img/brand/logo.svg';
import { FooterData } from '../../data/FooterData';
import { useState } from 'react';
import { LangData } from '../../utils/LangData';

const Footer = ({ width, lang }) => {
  const [selected, setSelected] = useState();
  return (
    <>
      <footer className={style.footer}>
        <br></br>
        <>
          {width > 940 ? (
            <>
              <div className={style.top}>
                {LangData[lang].component.footer.map(item => {
                  return (
                    <div className={style.row}>
                      <h5>{item.title}</h5>
                      <ul>
                        {item.links.map(i => {
                          return (
                            <li>
                              <a href={i.path}>{i.title}</a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
              <div className={style.bottom}>
                <h6>All rights reserved</h6>
                <div className={style.logo}>
                  <img src={Logo} alt="logo" />
                  <LogoStroke />
                </div>
                <div className={style.lang}>
                  <h5>Dil Seçeneği: TR ₺</h5>
                </div>
              </div>
            </>
          ) : (
            <div className={style.mobile}>
              <div className={style.buttonWrapper}>
                {LangData[lang].component.footer.map((item, index) => {
                  const id = index + 1;
                  return (
                    <div className={style.linkWrapper}>
                      <button
                        onClick={() => setSelected(selected === id ? '' : id)}
                        className={selected === id ? style.activeButton : null}
                      >
                        <h5>{item.title}</h5>
                        <FooterDownArrow />
                      </button>
                      {selected === id ? (
                        <ul>
                          {FooterData.filter(
                            (i, index) => index + 1 === selected
                          ).map(item => {
                            return item.links.map(i => {
                              return (
                                <li>
                                  <a href={i.path}>{i.title}</a>
                                </li>
                              );
                            });
                          })}
                        </ul>
                      ) : null}
                    </div>
                  );
                })}
              </div>
              <div className={style.bottom}>
                <div className={style.copyright}>
                  <h6>All rights reserved</h6>
                  <div className={style.lang}>
                    <h5>Dil Seçeneği: TR ₺</h5>
                  </div>
                </div>
                <div className={style.logo}>
                  <img src={Logo} alt="logo" />
                  <LogoStroke />
                </div>
              </div>
            </div>
          )}
        </>
      </footer>
    </>
  );
};

export default Footer;
