import style from './products.module.scss';
import ProductImage from '../../../assets/img/dummy/product.png';
import ProductImage2 from '../../../assets/img/home/render_png.png';
import { changeQuantity } from '../../../redux/actions';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { ApiRoutes } from '../../../utils/ApiRoutes';

const ProductCard = ({
  id,
  image,
  title,
  price,
  info,
  quantity,
  productId,
  updated,
  deleteClick,
}) => {
  const dispatch = useDispatch();

  const changeFunc = type => {
    axios
      .post(ApiRoutes.basket.update, {
        id: id,
        productId: productId,
        quantity:
          type === 'up'
            ? quantity + 1
            : type === 'down' && quantity !== 1
            ? quantity - 1
            : quantity,
      })
      .then(res => {
        console.log(res.data);
        updated();
      })
      .catch(err => {
        console.log(err);
      });

    // dispatch(
    //   changeQuantity({
    //     id: id,
    //     title: title,
    //     price: price,
    //     info: info,
    //     quantity:
    //       type === 'up'
    //         ? quantity + 1
    //         : type === 'down' && quantity !== 1
    //         ? quantity - 1
    //         : quantity,
    //   })
    // );
  };

  return (
    <div className={style.productCard}>
      <img alt={title} src={image} className={style.mainImage} />
      <div className={style.info}>
        <h3>{title}</h3>
        <h4>{price} TL</h4>
        <h5>{info}</h5>
        <div className={style.buttonWrapper}>
          <div className={style.quantity}>
            <button onClick={() => changeFunc('down')}>-</button>
            <span>{quantity} ADET</span>
            <button onClick={() => changeFunc('up')}>+</button>
          </div>
        </div>
        <button onClick={deleteClick} className={style.delete}>
          {'DELETE'}
        </button>
      </div>
      <div className={style.mobileButtonWrapper}>
        <button>EDIT</button>
        <button onClick={deleteClick}>DELETE</button>
      </div>
    </div>
  );
};

const Products = ({ data, cart, updated, deleteClick }) => {
  return (
    <div className={style.products}>
      <h1>Alışveriş Sepetim</h1>
      <div className={style.productWrapper}>
        {data.map(item => {
          return (
            <ProductCard
              title={item.name}
              image={item.productPhoto}
              info={
                'FRATELLI DIAMANTI - DIAMOND COLLECTION - EXTRAIT DE PARFUM - 65 ML / NATURAL SPRAY'
              }
              price={item.price * item.quantity}
              id={item.id}
              quantity={item.quantity}
              productId={item.productId}
              updated={updated}
              deleteClick={() => {
                deleteClick({
                  id: item.id,
                  productId: item.productId,
                });
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Products;
