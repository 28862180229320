import style from './productinfo.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../redux/actions';
import axios from 'axios';
import { ApiRoutes } from '../../utils/ApiRoutes';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LangData } from '../../utils/LangData';

const ProductInfo = ({
  basketData,
  name,
  price,
  id,
  lang,
  info,
  subTitle,
  priceLine,
  subTitleCap,
}) => {
  const dispatch = useDispatch();
  const cartState = useSelector(state => state.cart.cart);
  const hasCart = basketData && basketData.map(i => i.productId).includes(id);
  const [added, setAdded] = useState(false);
  const isAuth = localStorage.getItem('token');
  const navigate = useNavigate();

  const addCart = () => {
    axios
      .post(`${ApiRoutes.basket.add}`, {
        productId: id,
        quantity: 1,
      })
      .then(res => {
        console.log(res.data);
        setAdded(true);

        dispatch(
          addToCart({
            id: id,
            title: name,
            info: 'FRATELLI DIAMANTI - DIAMOND COLLECTION - EXTRAIT DE PARFUM - 65 ML / NATURAL SPRAY',
            price: price,
            quantity: 1,
          })
        );
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div className={style.productInfo}>
      <h1>{name}</h1>

      <h2>
        {priceLine ? (
          <>
            {' '}
            <p className={style.cross}>
              {' '}
              <span className={style.striketextcolor}> ₺{priceLine}</span>
            </p>{' '}
          </>
        ) : null}
        ₺{price}
      </h2>
      <h3>
        {subTitleCap} - {subTitle}
      </h3>
      <button
        onClick={() => {
          if (!hasCart && isAuth) {
            addCart();
          } else if (!isAuth) {
            localStorage.setItem('redirectProduct', 'true');
            navigate('/login');
          }
        }}
        className={`${style.addToCart} ${(hasCart || added) && style.hasCart}`}
      >
        {hasCart || added
          ? LangData[lang].product.info.alreadyAdded
          : LangData[lang].product.info.addToCart}
      </button>
      <div className={style.info}>
        <h4>{LangData[lang].product.info.title}</h4>
        <p dangerouslySetInnerHTML={{ __html: info }}></p>
        {/* {LangData[lang].product.info.paragraph.map(item => {
          return ;
        })} */}
      </div>
      <div className={style.buttonWrapper}>
        {LangData[lang].product.info.button.slice(0, 2).map(item => {
          return <button>{item}</button>;
        })}
        <button>{subTitleCap}</button>
      </div>
    </div>
  );
};

export default ProductInfo;
