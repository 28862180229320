import style from './topbar.module.scss';
import { useNavigate } from 'react-router-dom';

const TopBar = ({ type, text, logOut, link }) => {
  const navigate = useNavigate();
  return (
    <div className={style.topBar}>
      <div className={style.wrapper}>
        {type ? (
          <ul>
            <li
              style={{
                width: logOut ? '100%' : 'auto',
              }}
            >
              <a href={link ? link : '/cart?section=product'}>
                {text ? text : 'ALIŞVERİŞ SEPETİM'}
              </a>
              {logOut ? (
                <button
                  onClick={() => {
                    localStorage.removeItem('token');
                    navigate('/login');
                  }}
                >
                  ÇIKIŞ YAP
                </button>
              ) : null}
            </li>
            {text ? null : <span>/</span>}
            {type === 'address' || type === 'payment' ? (
              <>
                <li>
                  <a href="/cart?section=address">MÜŞTERİ BİLGİLERİ</a>
                </li>
                <span>/</span>
              </>
            ) : null}

            {type === 'payment' && (
              <>
                <li>ÖDEME SEÇENEKLERİ </li>
              </>
            )}
          </ul>
        ) : (
          <ul>
            <li>PARFUM</li>
            <span>/</span>
            <li>DIAMOND COLLECTION</li>
            <span>/</span>
            <li>SHADES OF GREY</li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default TopBar;
